import { ErrorToast } from "@src/components/sharedComponents/toasts/ErrorToast";
import { SuccessToast } from "@src/components/sharedComponents/toasts/SuccessToast";
import { setIsLoading } from "@src/redux/feature/modalHandler";
import { RootState } from "@src/redux/store";
import { LoggerContext, RepositoryContext } from "@src/shared/contexts";
import _ from "lodash";
import { useContext, useEffect, useState } from "react";
import { useErrorBoundary } from "react-error-boundary";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useParams } from "react-router-dom";

interface Redirect_Url {
  redirect_url: string;
}

const MainSubs = () => {
  const isActive = false;
  const [billingData, setBillingData] = useState<invoiceData | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [formattedStartDate, setFormattedStartDate] = useState<string | null>(
    null
  );
  const [formattedEndDate, setFormattedEndDate] = useState<string | null>(null);
  const [secondFormattedEndDate, setSecondFormattedEndDate] = useState<
    string | null
  >(null);
  const [differenceInDays, setDifferenceInDays] = useState<number | null>(null);
  const [progressWidth, setProgressWidth] = useState<number>(0);

  const {
    register,
    handleSubmit,
    setError,
    setValue,
    formState: { errors },
  } = useForm<billingData>();

  const params = useParams();
  const isLoading = useSelector(
    (state: RootState) => state.modalHandler.isLoading
  );

  const OrgId = parseInt(params.orgId!);
  const { showBoundary } = useErrorBoundary();
  const customLogger = useContext(LoggerContext);
  const repository = useContext(RepositoryContext);
  const subscriptionRepo = repository.subscription;
  const dispatch = useDispatch();

  const onSubmit = async (data: billingData) => {
    dispatch(setIsLoading(true));
    try {
      const response = await subscriptionRepo.createInvoice<
        billingResponse,
        billingData
      >(OrgId, data);

      if (!_.isUndefined(response)) {
        if ("errors" in response) {
          _.map(response.errors, (error) => {
            const validField = error.title as "name" | "email";
            if (validField === "name" || "email") {
              setError(validField, { message: error.message });
            }
            if (validField !== "name" && validField !== "email") {
              ErrorToast({ Message: error.message });
            }
          });
        } else {
          SuccessToast({
            Message: "Success! Your invoice data has been saved.",
          });
          setIsEditing(false);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(setIsLoading(false));
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await subscriptionRepo.getBillingAddress<invoiceData>(
        OrgId
      );
      if (response) {
        if ("errors" in response) {
          await customLogger.reportErrorResponse(showBoundary, response);
        } else {
          setBillingData(response);
        }
      }
    };
    fetchData().catch(async (error) => {
      showBoundary(error);
      await customLogger.reportError(error);
    });
  }, [subscriptionRepo, OrgId, customLogger, showBoundary]);

  const handleGetPreviousData = async () => {
    try {
      const response = await subscriptionRepo.getPreviousData<Redirect_Url>(
        OrgId
      );
      if (response) {
        if ("errors" in response) {
          await customLogger.reportErrorResponse(showBoundary, response);
        } else {
          window.open(response.redirect_url, "_blank");
        }
      }
    } catch (error) {
      showBoundary(error);
      await customLogger.reportError(error);
    }
  };

  useEffect(() => {
    setValue("name", billingData?.customer.name!);
    setValue("email", billingData?.customer.billing_address!);
  }, [
    setValue,
    billingData?.customer.billing_address,
    billingData?.customer.name,
  ]);

  useEffect(() => {
    const startDateValue: string | undefined =
      billingData?.current_subscription?.start_date;
    const endDateValue: string | undefined =
      billingData?.current_subscription?.end_date;

    if (startDateValue && endDateValue) {
      const startDate = new Date(startDateValue);
      const endDate = new Date(endDateValue);
      const today = new Date();

      if (!isNaN(startDate.getTime()) && !isNaN(endDate.getTime())) {
        const formatter = new Intl.DateTimeFormat("en-US", {
          month: "long",
          day: "numeric",
        });

        const secondFormatter = new Intl.DateTimeFormat("en-US", {
          month: "short",
          day: "numeric",
          year: "numeric",
        });

        setFormattedStartDate(formatter.format(startDate));
        setFormattedEndDate(formatter.format(endDate));
        setSecondFormattedEndDate(secondFormatter.format(endDate));

        const totalCycleTime = endDate.getTime() - startDate.getTime();
        const totalDays = Math.ceil(totalCycleTime / (1000 * 3600 * 24));
        const remainingTime = endDate.getTime() - today.getTime();
        const remainingDays = Math.ceil(remainingTime / (1000 * 3600 * 24));

        setDifferenceInDays(remainingDays);

        if (totalDays > 0) {
          const percentageCompleted =
            ((totalDays - remainingDays) / totalDays) * 100;
          setProgressWidth(percentageCompleted);
        } else {
          setProgressWidth(0);
        }
      } else {
        console.error("Invalid start or end date format.");
      }
    } else {
      console.error("Start date or end date is missing or undefined.");
    }
  }, [billingData]);

  return (
    <>
      {isActive ? (
        <>
          <div className="fixed w-[100%] h-[100%] bg-bg_shadow opacity-25 z-[1]"></div>
          <div className="fixed 2xl:w-[85] w-[80%] h-[90%] flex items-center justify-center z-[3]">
            <div className="w-[600px] h-[300px] flex justify-center items-center rounded-md bg-white shadow-lg">
              <div className="flex flex-col items-center gap-[16px]">
                <img
                  width={50}
                  height={50}
                  src="/images/exception.png"
                  alt="Exception"
                />
                <h1 className="font-bold text-[#172B4D] text-[28px]">
                  Subscription Purchase Coming Soon !
                </h1>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="md:px-[36px] flex flex-col h-[91vh] w-full">
          <h1 className="text-[24px] font-[500] tracking-[0.5px] text-text_dark_blue mt-[8px] z-[1]">
            {billingData?.customer.name} Organization
          </h1>
          <div className="mt-[24px] mb-[24px] flex flex-col gap-[18px]">
            <div className="py-[12px] px-[22px] bg-[#F9FBFF] border border-[#D9DCED] rounded-[3px]">
              <div className="grid grid-cols-3">
                <div className="col-span-1">
                  <p className="text-[16px] font-bold">Subscription plan</p>
                </div>
                <div className="col-span-2">
                  <p>This organization is currently on</p>
                  <div className="flex items-center gap-[46px] mt-[26px]">
                    <h1 className="text-[24px] text-[#172B4D] font-bold">
                      {billingData?.current_subscription.plan_name} Plan
                    </h1>
                    <NavLink to="plan">
                      <button
                        type="button"
                        className="border border-[#162C5B] py-[8px] px-[14px] text-[14px] font-bold text-[#162C5B] rounded-[3px]"
                      >
                        Change Subscription Plan
                      </button>
                    </NavLink>
                  </div>
                  <div className="mt-[20px] border border-[#D9DCED] text-[16px] py-[10px] px-[20px]">
                    <div>
                      <p className="font-bold text-[#777777]">
                        This organization is under{" "}
                        {billingData?.current_subscription.plan_name} plan
                      </p>
                    </div>
                    <p className="font-[400]">
                      You currently have the benefits of our{" "}
                      {billingData?.current_subscription.plan_name} plan for
                      issue tracking and AI code fixes.
                    </p>
                  </div>
                  <div className="mt-[52px]">
                    <div className="flex justify-between text-[16px]">
                      <p className="font-bold">
                        Current billing cycle ({formattedStartDate} -
                        {formattedEndDate})
                      </p>
                      <p>{differenceInDays} days remaining</p>
                    </div>
                    <div className="bg-[#CFDBEE] rounded-[8px] h-[8px] mt-[14px]">
                      <div
                        style={{ width: `${progressWidth}%` }}
                        className="bg-[#325EBB] rounded-[8px] h-full"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="py-[12px] px-[22px] bg-[#F9FBFF] border border-[#D9DCED] rounded-[3px]">
              <div className="grid grid-cols-3">
                <div className="col-span-1 flex flex-col gap-[15px]">
                  <p className="text-[16px] font-bold">Billing Breakdown</p>
                  <p className="text-[14px] font-[400]">
                    Current Billing Cycle : {formattedStartDate} -
                    {formattedEndDate}
                  </p>
                  <button
                    onClick={handleGetPreviousData}
                    className="text-[14px] font-[400] underline text-start"
                  >
                    View previous months bill
                  </button>
                </div>
                <div className="col-span-2">
                  <p className="text-[14px] font-[400]">
                    This table shows your upcoming invoice, excluding credits.
                    This invoice will continue updating until the end of your
                    billing period on {formattedEndDate}.
                  </p>
                  <div className="bg-[#F0F4FF] py-[14px] px-[20px] mt-[8px]">
                    <div className="relative overflow-x-auto">
                      <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                        <thead className="text-[14px] border-b border-[#C0C0C0]">
                          <tr>
                            <th scope="col" className="px-6 py-3">
                              Item
                            </th>
                            <th scope="col" className="px-6 py-3 text-center">
                              Usage
                            </th>
                            <th scope="col" className="px-6 py-3 text-center">
                              Unit Price
                            </th>
                            <th scope="col" className="px-6 py-3 text-end">
                              Cost
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr className="border-b border-[#C0C0C0]">
                            <td className="px-6 py-4">
                              {billingData?.current_subscription.plan_name} Plan
                            </td>
                            <td className="px-6 py-4 text-center">
                              {billingData?.upcomming_invoice.quantity}
                            </td>
                            <td className="px-6 py-4 text-center">
                              ${billingData?.upcomming_invoice.price}
                            </td>
                            <td className="px-6 py-4 text-end">
                              ${billingData?.upcomming_invoice.price}
                            </td>
                          </tr>
                          <tr>
                            <th scope="row" className="px-6 py-4">
                              Total
                            </th>
                            <td className="px-6 py-4"></td>
                            <td className="px-6 py-4"></td>
                            <th scope="row" className="px-6 py-4 text-end">
                              ${billingData?.upcomming_invoice.total_due_amount}
                            </th>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="py-[22px] px-[22px] bg-[#F9FBFF] border border-[#D9DCED] rounded-[3px]">
              <div className="grid grid-cols-3">
                <div className="col-span-1">
                  <p className="text-[16px] font-bold">Balance</p>
                  <p className="text-[14px] font-[400]">
                    Valid till {secondFormattedEndDate}
                  </p>
                </div>
                <div className="col-span-2 flex items-center">
                  <p className="text-[32px] text-[#172B4D] font-bold">
                    USD {billingData?.upcomming_invoice.total_due_amount}.00
                  </p>
                </div>
              </div>
            </div>
            <div className="py-[12px] px-[22px] bg-[#F9FBFF] border border-[#D9DCED] rounded-[3px] mb-[24px]">
              <div className="grid grid-cols-3">
                <div className="col-span-1">
                  <p className="text-[16px] font-bold">Bill To</p>
                  <p className="text-[14px] font-[400]">
                    All billing details will go to this address{" "}
                  </p>
                </div>
                <div className="col-span-2">
                  <form
                    onSubmit={handleSubmit(onSubmit)}
                    className="flex flex-col gap-[12px]"
                  >
                    <div className="flex gap-[18px] items-center">
                      <label
                        htmlFor="Name"
                        className="w-[150px] text-[14px] font-[400]"
                      >
                        Company Name<span>*</span>
                      </label>
                      <div className="flex flex-col w-[100%]">
                        <input
                          type="text"
                          disabled={!isEditing}
                          placeholder="ABC Corporations Pvt. Ltd"
                          {...register("name", {
                            required: "Name Required",
                          })}
                          className="border border-[#D9DCED] w-[100%] py-[10px] px-[14px] rounded-[3px]"
                        />
                        {errors.name != null && (
                          <small className="block text-red mt-2">
                            {errors.name.message}
                          </small>
                        )}
                      </div>
                    </div>
                    <div className="flex gap-[18px] items-center">
                      <label
                        htmlFor="Name"
                        className="w-[150px] text-[14px] font-[400]"
                      >
                        Email
                      </label>
                      <div className="flex flex-col w-[100%]">
                        <input
                          type="email"
                          disabled={!isEditing} // Disable input when not editing
                          placeholder="abccorporations@email.com"
                          {...register("email", {
                            required: "Email Required",
                          })}
                          className="border border-[#D9DCED] w-[100%] py-[10px] px-[14px] rounded-[3px]"
                        />
                        {errors.email != null && (
                          <small className="block text-red mt-2">
                            {errors.email.message}
                          </small>
                        )}
                      </div>
                    </div>
                    {isEditing && (
                      <div className="flex gap-[20px] mt-[34px]">
                        {isLoading ? (
                          <button
                            disabled
                            className="flex gap-[12px] bg-[#325EBB] text-[#FFFFFF] font-bold py-[8px] px-[40px] rounded-[6px] text-[14px] font-[500]"
                          >
                            <img
                              width={22}
                              height={22}
                              src="/icons/Loading.svg"
                              alt="loading"
                            />
                            Saving
                          </button>
                        ) : (
                          <button
                            type="submit"
                            className="bg-[#325EBB] text-[#FFFFFF] font-bold py-[8px] px-[40px] rounded-[6px] text-[14px] font-[500]"
                          >
                            Save
                          </button>
                        )}

                        <button
                          type="button"
                          onClick={() => setIsEditing(false)}
                          className="border border-[#162C5B] py-[8px] px-[40px] rounded-[6px] text-[14px] font-[500]"
                        >
                          Cancel
                        </button>
                      </div>
                    )}
                  </form>
                  {!isEditing && (
                    <div className="flex gap-[20px] mt-[34px]">
                      <button
                        type="button"
                        onClick={() => setIsEditing(true)}
                        className="border border-[#162C5B] py-[8px] px-[40px] rounded-[6px] text-[14px] font-[500]"
                      >
                        Edit
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MainSubs;
