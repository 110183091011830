import {
  setIsOverlayVisible,
  setIsSubscriptionSuccessModalOpen,
} from "@src/redux/feature/modalHandler";
import { useDispatch } from "react-redux";

const SubscriptionSuccessModal = () => {
  const dispatch = useDispatch();
  const handleCloseSubscriptionSuccessModal = () => {
    dispatch(setIsSubscriptionSuccessModalOpen(false));
    dispatch(setIsOverlayVisible(false));
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-[2]">
      <div className="bg-white rounded-md z-[2] py-[58px] md:px-[32px] w-[354px] md:w-[570px]">
        <div className="flex flex-col justify-center items-center gap-[18px]">
          <img
            width={70}
            height={70}
            src="/icons/Tickmark.svg"
            alt="Tick Mark"
          />
          <p className="text-[20px] font-bold">Congratulations!!</p>
          <p className="text-[14px]">
            You have been upgraded to our Premium Plan.
          </p>
          <button
            onClick={handleCloseSubscriptionSuccessModal}
            className="text-[18px] text-[#001789]"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionSuccessModal;
