import Pagination from "@src/components/Pagination";
import CustomToolTip from "@src/components/sharedComponents/CustomToolTip";
import InfoTooltip from "@src/components/sharedComponents/buttons/InfoTooltip";
import AddMemberModal from "@src/components/sharedComponents/modal/AddMemberModal";
import DeleteMemberModal from "@src/components/sharedComponents/modal/DeleteMemberModal";
import EditMemberModal from "@src/components/sharedComponents/modal/EditMemberModal";
import MemberProjectsModel from "@src/components/sharedComponents/modal/MemberProjectsModel";
import { ErrorToast } from "@src/components/sharedComponents/toasts/ErrorToast";
import { SuccessToast } from "@src/components/sharedComponents/toasts/SuccessToast";
import {
  setMemberData,
  setMetaData,
  setSelectedMemberId,
} from "@src/redux/feature/memberSlice";
import {
  setIsAddMemberModalOpen,
  setIsDeleteMemberModalOpen,
  setIsEditMemberModalOpen,
  setIsMemberProjectsModalOpen,
  setIsOverlayVisible,
} from "@src/redux/feature/modalHandler";
import { RootState } from "@src/redux/store";
import { LoggerContext, RepositoryContext } from "@src/shared/contexts";
import _, { debounce } from "lodash";
import { useContext, useEffect, useRef, useState } from "react";
import { useErrorBoundary } from "react-error-boundary";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

const AccessManagement = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [query, setQuery] = useState("");
  const [openDropdownId, setOpenDropdownId] = useState<number | null>(null);
  const [clickedId, setClickedId] = useState<number | null>(null);
  const divRefs = {
    divRef1: useRef<HTMLDivElement>(null),
  };

  const params = useParams();
  const orgId = params.orgId;
  const projectid = params.id;
  const dispatch = useDispatch();
  const memberSlice = useSelector((state: RootState) => state.memberSlice);

  const role = memberSlice.current_member?.role;
  const email = memberSlice.current_member?.email;

  const isAddMemberModalOpen = useSelector(
    (state: RootState) => state.modalHandler.isAddMemberModalOpen
  );
  const isEditMemberModalOpen = useSelector(
    (state: RootState) => state.modalHandler.isEditMemberModalOpen
  );
  const isDeleteMemberModalOpen = useSelector(
    (state: RootState) => state.modalHandler.isDeleteMemberModalOpen
  );
  const IsMemberProjectsModalOpen = useSelector(
    (state: RootState) => state.modalHandler.isMemberProjectsModalOpen
  );

  const toggleDropdown = (id: number) => {
    setOpenDropdownId((prevId) => (prevId === id ? null : id));
  };

  const openAddMemberModal = () => {
    dispatch(setIsAddMemberModalOpen(true));
    dispatch(setIsOverlayVisible(true));
  };

  const closeAddMemberModal = () => {
    dispatch(setIsAddMemberModalOpen(false));
    dispatch(setIsOverlayVisible(false));
  };

  const openEditMemberModal = () => {
    dispatch(setIsEditMemberModalOpen(true));
    dispatch(setIsOverlayVisible(true));
  };

  const closeEditMemberModal = () => {
    dispatch(setIsEditMemberModalOpen(false));
    dispatch(setIsOverlayVisible(false));
  };

  const openDeleteMemberModal = () => {
    dispatch(setIsDeleteMemberModalOpen(true));
    dispatch(setIsOverlayVisible(true));
  };

  const closeDeleteMemberModal = () => {
    dispatch(setIsDeleteMemberModalOpen(false));
    dispatch(setIsOverlayVisible(false));
  };

  const openMemberProjects = (id: number) => {
    dispatch(setSelectedMemberId(id));
    dispatch(setIsMemberProjectsModalOpen(true));
    dispatch(setIsOverlayVisible(true));
  };

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (event.target instanceof HTMLElement) {
        if (!event.target.closest(".dropdown")) {
          setOpenDropdownId(null);
        }
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  const { showBoundary } = useErrorBoundary();
  const customLogger = useContext(LoggerContext);
  const modalHandler = useSelector((state: RootState) => state.modalHandler);
  const repository = useContext(RepositoryContext);
  const memberRepo = repository.member;

  const debouncedFetchMember = useRef(
    debounce(async (orgId, currentPage, query) => {
      try {
        const memberList = await memberRepo.getMember<MemberData[]>(
          orgId,
          currentPage,
          query
        );
        if (!_.isUndefined(memberList)) {
          if ("errors" in memberList) {
            await customLogger.reportErrorResponse(showBoundary, memberList);
          } else {
            dispatch(setMemberData(memberList));
            dispatch(setMetaData(memberList));
          }
        }
      } catch (error) {
        showBoundary(error);
        await customLogger.reportError(error);
      }
    }, 300)
  ).current;

  useEffect(() => {
    debouncedFetchMember(orgId, currentPage, query);

    return () => {
      debouncedFetchMember.cancel();
    };
  }, [
    customLogger,
    dispatch,
    memberRepo,
    showBoundary,
    modalHandler.isDeleteMemberModalOpen,
    modalHandler.isAddMemberModalOpen,
    modalHandler.isEditMemberModalOpen,
    currentPage,
    projectid,
    query,
    orgId,
  ]);

  const { total_pages, total_items, has_next_page, has_previous_page } =
    memberSlice.meta_data || {};

  const handleNextPage = () => {
    if (has_next_page) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (has_previous_page) {
      setCurrentPage(currentPage - 1);
    }
  };

  const onResend = async (id: number) => {
    try {
      const response = await memberRepo.resendInvite(id);
      if (!_.isUndefined(response)) {
        const info: InfoLog = {
          message: "Sent an Invite.",
        };
        await customLogger.log(info);
        SuccessToast({ Message: "Email has been re-sent" });
      } else if ("errors" in response) {
        ErrorToast({ Message: "Error occurred while Sending an Invite!" });
        await customLogger.reportErrorResponse(showBoundary, response);
      }
    } catch (error) {
      showBoundary(error);
      await customLogger.reportError(error);
    }
  };

  return (
    <div className="pb-[25px] md:pb-[36px] px-[25px] md:px-[36px] pt-[15px]">
      <div className="text-[26px] leading-[33.85px] font-bold">Members</div>
      <div className="flex flex-col mt-4">
        <div className="flex w-full gap-6">
          <div className="flex mb-5 w-[90%] h-[38px] bg-white items-center border border-border_gray rounded-[8px] px-3 ">
            <img
              src="/icons/Search.svg"
              alt="SearchIcon"
              className="w-[14px]"
            />
            <input
              value={query}
              onChange={(e) => {
                setQuery(e.target.value);
                debouncedFetchMember(orgId, currentPage, e.target.value);
              }}
              type="text"
              className="w-full h-[30px] outline-none focus:outline-none rounded-[8px] ml-2"
              placeholder="Search name and email"
            />
          </div>
          <button
            onClick={openAddMemberModal}
            disabled={role === "Basic"}
            className={`w-[140px] h-[38px] bg-[#325EBB] rounded-[6px] text-[12px] text-[#FFFFFF] ${
              role === "Basic" ? "opacity-30 cursor-not-allowed" : ""
            }`}
          >
            + Add Members
          </button>
        </div>
        <div className="py-[11px]">
          <div className=" rounded-lg border border-[#DDDDCCED]">
            <table className="rounded-lg text-[#292C31] bg-[#F3F4F8] w-full">
              <thead className="text-[14px] text-left">
                <tr>
                  <th className="font-[700] py-3 px-6">Name</th>
                  <th className="font-[700] py-3 px-6"></th>
                  <th className="font-[700] py-3 px-6">Email</th>
                  <th className="font-[700] py-3 px-6">
                    <div className="flex items-center">
                      <p>Role</p>
                      <div>
                        <InfoTooltip
                          divRef={divRefs.divRef1}
                          messages={[
                            "Owner and Editor: Have read and write Permissions",
                            "Basic: Has only read Permissions",
                          ]}
                          bgColor="bg-[#FAFAFA]"
                          textColor="text-[#454545]"
                        />
                      </div>
                    </div>
                  </th>
                  <th className="font-[700] py-3 px-6">Added On</th>
                  <th className="font-[700] py-3 px-6">Project</th>
                  <th className="font-[700] py-3 px-6 text-center">Action</th>
                </tr>
              </thead>
              <tbody className="text-[12px] ">
                {memberSlice.member_data?.map((item, index) => (
                  <tr
                    key={item.id}
                    className={index % 2 === 0 ? "bg-white" : "bg-[#FAFAFA]"}
                  >
                    <td
                      className={`${
                        index == memberSlice.member_data.length - 1
                          ? "rounded-bl-lg"
                          : ""
                      } py-3 px-6`}
                    >
                      <div className=" text-[#292C31] max-w-[200px] truncate">
                        {item.name}
                      </div>
                    </td>
                    <td className="py-3 px-6 text-[#FF941F] flex justify-center">
                      <div
                        className={`${
                          item.status === "accepted" ? "hidden" : "block"
                        } h-[25px] w-[126px] bg-opacity-[0.25] bg-[#FF941F] rounded-full text-center `}
                      >
                        <p className="pt-1">
                          {item.status === "pending"
                            ? "Invitation Pending"
                            : ""}
                        </p>
                      </div>
                    </td>
                    <td className="py-3 px-6 text-[#292C31] truncate max-w-[250px]">
                      {item.email}
                    </td>
                    <td className="py-3 px-6 text-[#292C31]">
                      {item.role &&
                        item.role.replace(/\b\w/g, (char) =>
                          char.toUpperCase()
                        )}
                    </td>
                    <td className="py-3 px-6 text-[#292C31]">
                      {new Date(item.created_at).toLocaleDateString("en-US", {
                        day: "2-digit",
                        month: "short",
                        year: "numeric",
                      })}
                    </td>
                    <td className="py-3 px-6 text-center">
                      <div className="flex  gap-2 text-[12px] items-center ">
                        {_.map(item.projects, (project, index) =>
                          index < 5 ? (
                            <>
                              <CustomToolTip text={project.title} width="">
                                <div className="flex text-center justify-center items-center  bg-[#777777] rounded-[3px] p-1 text-[#FFFFFF] w-[27px] h-[23px] truncate">
                                  {project.title
                                    .split(" ")
                                    .slice(0, 2)
                                    .map((word) => word[0])
                                    .join("")
                                    .toUpperCase()}
                                </div>
                              </CustomToolTip>
                            </>
                          ) : null
                        )}
                        {item.projects &&
                          item.projects.length < 5 &&
                          _.times(5 - item.projects.length, () => (
                            <div className="flex justify-center items-center bg-[#DEDEDE] rounded-[3px] p-1 text-[#FFFFFF] w-[27px] h-[23px]"></div>
                          ))}
                        {item.projects.length > 0 && (
                          <div
                            className=" rounded-[3px] text-[#1054D9] w-fit cursor-pointer "
                            onClick={() => openMemberProjects(item.id)}
                          >
                            View All
                          </div>
                        )}
                      </div>
                    </td>
                    <td
                      className={`${
                        index == memberSlice.member_data.length - 1
                          ? "rounded-br-lg"
                          : ""
                      } py-3 px-6 text-center relative`}
                    >
                      {role !== "Basic" ? (
                        <>
                          {role === "Editor" && item.role === "Owner" ? (
                            ""
                          ) : (
                            <button
                              onClick={() => toggleDropdown(item.id)}
                              className="inline-flex justify-center items-center"
                              type="button"
                            >
                              <div className="flex items-center">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="50"
                                  height="17"
                                  viewBox="0 0 10 17"
                                  fill="none"
                                >
                                  <path
                                    d="M1.875 0C0.84375 0 0 0.85 0 1.88889C0 2.92778 0.84375 3.77778 1.875 3.77778C2.90625 3.77778 3.75 2.92778 3.75 1.88889C3.75 0.85 2.90625 0 1.875 0ZM1.875 13.2222C0.84375 13.2222 0 14.0722 0 15.1111C0 16.15 0.84375 17 1.875 17C2.90625 17 3.75 16.15 3.75 15.1111C3.75 14.0722 2.90625 13.2222 1.875 13.2222ZM1.875 6.61111C0.84375 6.61111 0 7.46111 0 8.5C0 9.53889 0.84375 10.3889 1.875 10.3889C2.90625 10.3889 3.75 9.53889 3.75 8.5C3.75 7.46111 2.90625 6.61111 1.875 6.61111Z"
                                    fill="#292C31"
                                  />
                                </svg>
                              </div>
                            </button>
                          )}

                          {openDropdownId === item.id && (
                            <div className="absolute right-20 top-0 z-[1] px-4 py-2 w-[200px] bg-white rounded shadow-lg">
                              <ul className="text-sm text-start font-medium">
                                <li
                                  className={`mb-3 ${
                                    item.status === "pending"
                                      ? "block"
                                      : "hidden"
                                  }`}
                                >
                                  <button
                                    onClick={() => {
                                      setClickedId(item.id);
                                      toggleDropdown(item.id);
                                      onResend(item.id);
                                    }}
                                    className="w-full flex justify-start items-center"
                                  >
                                    <img
                                      className="mr-2"
                                      height={14}
                                      width={14}
                                      src="/icons/Resend.svg"
                                      alt="resend"
                                    ></img>
                                    Resend Invite
                                  </button>
                                </li>
                                <hr
                                  className={`text-border_light_gray ${
                                    item.status === "pending"
                                      ? "block"
                                      : "hidden"
                                  }`}
                                />
                                <li
                                  className={`${
                                    item.status === "pending" ? "my-3" : "mb-0"
                                  }`}
                                >
                                  <button
                                    onClick={() => {
                                      setClickedId(item.id);
                                      toggleDropdown(item.id);
                                      openEditMemberModal();
                                    }}
                                    className="w-full flex justify-start"
                                  >
                                    <img
                                      className="mr-2"
                                      height={18}
                                      width={18}
                                      src="/icons/MemberEdit.svg"
                                      alt="edit"
                                    ></img>
                                    Edit
                                  </button>
                                </li>
                                {email === item.email ? (
                                  ""
                                ) : (
                                  <li className="mt-3 text-[#cc0202]">
                                    <hr className="text-border_light_gray mb-2" />
                                    <button
                                      onClick={() => {
                                        setClickedId(item.id);
                                        toggleDropdown(item.id);
                                        openDeleteMemberModal();
                                      }}
                                      className="w-full flex justify-start"
                                    >
                                      <img
                                        className="mr-2"
                                        height={18}
                                        width={18}
                                        src="/icons/MemberDelete.svg"
                                        alt="delete"
                                      ></img>
                                      Delete
                                    </button>
                                  </li>
                                )}
                              </ul>
                            </div>
                          )}
                        </>
                      ) : (
                        <>-</>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="flex justify-between items-center mt-[20px]">
            <p className="text-[12px] font-normal">
              Total {total_items} members
            </p>
            <Pagination
              totalPages={total_pages}
              currentPage={currentPage}
              onPageChange={setCurrentPage}
              hasNextPage={has_next_page}
              hasPreviousPage={has_previous_page}
              onNextPage={handleNextPage}
              onPreviousPage={handlePreviousPage}
            />
          </div>
        </div>
      </div>
      {isAddMemberModalOpen && (
        <AddMemberModal closeAddMemberModal={closeAddMemberModal} />
      )}
      {isEditMemberModalOpen && (
        <EditMemberModal
          closeEditMemberModal={closeEditMemberModal}
          memberId={clickedId}
        />
      )}
      {isDeleteMemberModalOpen && (
        <DeleteMemberModal
          closeDeleteMemberModal={closeDeleteMemberModal}
          memberId={clickedId}
        />
      )}
      {IsMemberProjectsModalOpen && <MemberProjectsModel />}
    </div>
  );
};

export default AccessManagement;
